import { useQuery, useInfiniteQuery, useQueryClient } from "react-query";
import { find, flatten, forEach, concat, groupBy, startsWith } from "lodash/fp";
import { v2API } from "@voxmarkets/api-sdk";

export const recentMediaAssetsKey = (mediaType) => [
  "mediaAssets",
  "recent",
  { mediaType },
];
export function recentMediaAssetsQuery(mediaType) {
  return {
    queryKey: recentMediaAssetsKey(mediaType),
    queryFn: () =>
      v2API.MediaAssets.getList({
        where: { mediaType: { like: `${mediaType}*` }, mediaIsClip: false },
        limit: 10,
      }),
    staleTime: 300000, // 5min
  };
}

export function recentMediaAssetsBySeries(mediaSeriesId, limit = 1){
  return {
    queryKey: recentMediaAssetsKey(mediaSeriesId),
    queryFn: () =>
      v2API.MediaAssets.getList({
        where: { mediaSeriesId, mediaIsClip: false },
        limit
      }),
    staleTime: 300000, // 5min
  };
}

export function useRecentMediaAssets(mediaType) {
  return useQuery(recentMediaAssetsQuery(mediaType));
}

export function useRecentSeriesMediaAssets(mediaSeriesId) {
  return useQuery(recentMediaAssetsBySeries(mediaSeriesId));
}

// ----

export function mediaAssetsQuery(objectId, config = {}, since) {
  const where = { mentions: `${objectId}` };
  if( since ){
    //where.createdAt = { $gte: since };
  }
  return {
    queryKey: ["mediaAssets", "mentions", { objectId }],
    queryFn: () =>
      v2API.MediaAssets.getList({
        where
      }),
    staleTime: 600000, // 10min
    ...config,
  };
}

export function useMediaAssets(objectId, since = 0) {
  const select = function( items ){
    return items.filter( item => new Date(item.createdAt) > since ).reduce( (acc, item) => {
      const mediaType = item.mediaType;
      if (
        startsWith("video", mediaType) ||
        mediaType === "application/bc-player" ||
        mediaType === "seeen"
      ) {
        acc.media.push(item);
      } else if (
        startsWith("audio", mediaType) ||
        mediaType === "embed/audioboom"
      ) {
        acc.media.push(item);
      } else if (mediaType === "application/pdf") {
        acc.documents.push(item);
      } else {
        acc.other.push(item);
      }
      return acc;
    }, { media: [], documents: [], other: [] });
  }

  return useQuery(mediaAssetsQuery(objectId, { select }, since));
}

//-----
export function seriesMediaAssetsQuery(seriesId, limit = 12) {
  return {
    queryKey: ["mediaAssets", "series", seriesId],
    queryFn: ({ pageParam = 0 }) =>
      v2API.MediaAssets.getList({
        where: { mediaSeriesId: `${seriesId}`, mediaIsClip: false },
        limit,
        skip: pageParam * limit,
      }),
    getNextPageParam: (lastPage, pages) =>
      lastPage?.length === limit ? pages.length : undefined,
    staleTime: 300000, // 5min
  };
}

export function useSeriesMediaAssets(seriesId, limit = 12) {
  return useInfiniteQuery(seriesMediaAssetsQuery(seriesId, limit));
}

// ---
export const mediaAssetKey = (mediaAssetId) => ["media-asset", mediaAssetId];
export function mediaAssetQuery(mediaAssetId, config) {
  return {
    queryKey: mediaAssetKey(mediaAssetId),
    queryFn: () => v2API.MediaAssets.get(mediaAssetId),
    staleTime: 28800000, // 8h
    ...config,
  };
}

function getQueriesData(queries) {
  let data = [];
  forEach((query) => {
    const pages = query.state?.data?.pages;
    const list = query.state?.data;
    data = concat(data, pages ? flatten(pages) : list);
  }, queries);

  return data;
}

export function useMediaAsset(mediaAssetId) {
  const queryClient = useQueryClient();

  const initialData = () => {
    const queryCache = queryClient.getQueryCache();
    const queries = queryCache.findAll(["mediaAssets"]);
    return find({ id: mediaAssetId }, getQueriesData(queries));
  };

  return useQuery(mediaAssetQuery(mediaAssetId, { initialData }));
}
