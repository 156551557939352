import React from "react";
import { useQuery } from "react-query";
import { useHomepageArticles, useCategoryArticles, useCategories } from "features/articles/queries";
import useMarketSectors from "hooks/useMarketSectors";

import { Link } from "react-router-dom";
import styled from "styled-components";

import { Grid, Cell, Border, Spacing } from "components/Layout";
import Text from "components/Text";

import Article from "features/articles/components/Article";


const StyledLink = styled(({ top, ...props }) => <Link {...props} />)`
  text-decoration: none;
  display: inline-block;
  padding:0.25rem 0;
&:hover {
text-decoration: underline;
}
  `;


const CategoryArticles = ({ includeCategories, fullWidth, link }) => {

  const { data: categories } = useCategories();
  const { data: articles } = useCategoryArticles( includeCategories, categories);

  if(fullWidth){
    return <Spacing horizontal={2}>
      <Grid>
        {articles && articles.pages[0] && articles.pages[0].slice(0,4).map( a => <Cell col={3}><Spacing bottom={0.5}><Article article={a} /></Spacing></Cell>)}
        {link && <Cell fill col={12}><StyledLink to={link.path}><Text center>See all {link.label}</Text></StyledLink></Cell>}
      </Grid>
      </Spacing>
  }
  
  return <Grid>
    {articles && articles.pages[0] && articles.pages[0].slice(0,4).map( a => <Cell col={6}><Spacing bottom={0.5}><Article row noLead article={a} /></Spacing></Cell>)}
  </Grid>
}

const NewsViews = () => {

  const { data: categories } = useCategories();
  const { data: articles } = useHomepageArticles();
  const { data: newswires } = useCategoryArticles( 'vox-newswire', categories);

  return <>
    <Grid>
      {newswires && newswires.pages[0] && newswires.pages[0].slice(0,4).map( a => <Cell col={3}><Spacing vertical={0.5}><Article noLead article={a} /></Spacing></Cell>)}
    </Grid>
    <Spacing vertical={1}>
      <Grid>
        {articles && articles.slice(6,12).map( a => <Cell col={6}><Spacing vertical={0.5}><Article row noLead article={a} /></Spacing></Cell>)}
      </Grid>
    </Spacing>
  </>
  
}

const MarketSectors = () => {

  const { supersectors, sectors } = useMarketSectors();
  const sorted = supersectors.sort( (a,b) => a.sectorDescription > b.sectorDescription ? 1 : -1 )
  const half = Math.ceil(sorted.length / 2);
  return <>
    <Text as="h3" bold size={1.2}>Market Sectors</Text>

  <Grid>
    <Cell col={6}>
      { sorted.slice(0,half).map( s => <div><StyledLink to={`/sector/${s.sectorLeafname}/`}><Text size={0.8}>{s.sectorDescription}</Text></StyledLink></div>) }
    </Cell>
    <Cell col={6}>
      { sorted.slice(half).map( s => <div><StyledLink to={`/sector/${s.sectorLeafname}/`}><Text size={0.8}>{s.sectorDescription}</Text></StyledLink></div> ) }
    </Cell>
  </Grid>

  </>

}

const Rns = () => {

  const { data: rns } = useQuery(
    "popularNews",
    async () => {
      const res = await fetch(`https://api.voxmarkets.co.uk/v4/rnsStatistics?report=today`);
      return res.json();
    },
    {
      staleTime: 300000
    }
  );

  const weekday = (new Date()).getDay();
  const week = weekday <= 1 ? 'past-week' : 'this-week';
  
  const { data: weekly } = useQuery(
    "popularNewsWeekly",
    async () => {
      const res = await fetch(`https://api.voxmarkets.co.uk/v4/rnsStatistics?report=${week}`);
      return res.json();
    },
    {
      staleTime: 300000
    }
);

  
  return <>
    <Grid>
      <Cell col={6}>
        <Text as="h3" bold>Most read today</Text>
        {rns && rns.slice(0,12).map( r => <div>
          <StyledLink to={`/rns/announcement/${r.storyId}/`}><Text size={0.8}>{r.storyHeadline}</Text></StyledLink>
        </div>)}
      </Cell>
      <Cell col={6}>
        <Text as="h3" bold>Popular this week</Text>
        {weekly && weekly.slice(0,12).map( r => <div>
          <StyledLink to={`/rns/announcement/${r.storyId}/`}><Text size={0.8}>{r.storyHeadline}</Text></StyledLink>
        </div>)}
      </Cell>
    </Grid>
  </>
  
}


const RelatedBox = ({ relatedKey, links, ...props }) => {
  if(relatedKey === "news-views"){
    return <NewsViews />;
  }
  if(relatedKey === "market-sectors"){
    return <MarketSectors />;
  }
  if(relatedKey === "rns"){
    return <Rns />;
  }
  else {
    const categories = links && links.filter(link => link.hasOwnProperty("category") && link.category !== "").map( link => link.category );
    if(categories && categories.length > 0){
      return <CategoryArticles includeCategories={ categories } fullWidth={links.length === 1} link={links[0]} />
    }
    return null;
  }
}

export {
  RelatedBox
}
